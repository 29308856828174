import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageIntro from '../components/page/page-intro2'
import UspPreviewGrid from '../components/usp/usp-preview-grid'
import UseCasePreviewGrid from '../components/usecase/usecase-preview-grid'
import PagePreviewGrid from '../components/page/page-preview-grid'
import {
  responsiveTitle2Centered,
  smallTitle,
  responsiveTitle2SansCentered,
} from '../components/typography.module.css'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import FeaturePreviewGrid from '../components/feature/feature-preview-grid'
import ContactDemo from '../components/contact/contact-demo'
import { Button } from '../components/Button/Button'
import { ContactModal } from '../components/contact/ContactModal'
import { PropTypes } from 'prop-types'
import { DemoPitch } from './../components/demoPitch';
import * as styles from '../styles/pages.module.css'
import BlockText from '../components/block-content/block-text'

export const query = graphql`
  query IndexPageQueryXX {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    index: sanityLanding(
      slug: { current: { eq: "index" } }
      mainImage: { asset: {} }
    ) {
      id
      title
      excerpt
      _rawBody
      heroes {
        title
        subTitle
        _rawExcerpt
        linkText
        linkUrl
        mainImage {
          alt
          asset {
            _id
          }
        }
        _id
      }
      _rawBullets
      usps {
        title
        subTitle
        _rawExcerpt
        linkText
        linkUrl
        _rawBullets
        _rawBody
        style {
          color
          title
        }
        mainImage {
          alt
          asset {
            _id
          }
        }
        _id
      }

      usecases {
        id
        mainImage {
          asset {
            _id
          }
          alt
        }
        title
        excerpt
        slug {
          current
        }
      }
      mainImage {
        asset {
          _id
        }
      }
      projectentries {
        id
        mainImage {
          asset {
            _id
          }
          alt
        }
        title
        publishedAt
        _rawExcerpt
        slug {
          current
        }
      }
      pageentries {
        id
        title
        excerpt
        slug {
          current
        }
      }
      relatedFeatures {
        title
        _rawExcerpt
        id
        title
        mainImage {
          asset {
            _id
          }
          alt
        }
        slug {
          current
        }
      }
    }

    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data, errors } = props
  const [showContactModal, setShowContactModal] = useState(false)
  const QuoteEng= '"Using cutting-edge technologies, we’re offering a market-leading, digital platform that connects patients and healthcare professionals together."'
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site
  const index = (data || {}).index

  const uspNodes = (data || {}).index.usps
  const useCasesNodes = (data || {}).index.usecases
  const projectNodes = (data || {}).index.projectentries
  const pageNodes = (data || {}).index.pageentries
  const featureNodes = (data || {}).index.relatedFeatures
  const bullets = data?.index._rawBullets

  // Splitting up the USPs so they can be presented in separate places in the page later on
  const highlightedUsp = uspNodes.slice(0, 1)
  const firstUsp = uspNodes.slice(1, 4)
  const secondUsps = uspNodes.slice(4, 7)
  const lastUsps = uspNodes.slice(7, 8)


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout
      textColor='white'
      backgroundImage={imageUrlFor(buildImageObj(index.mainImage))
        .height(Math.floor((12 / 16) * 1600))
        .url()}
    >

      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <h1 hidden>Welcome to {site.title}</h1>

      <PageIntro
        textColor='white'
        index={true}
        title={index.title}
        excerpt={index.excerpt}
      />
    

      <Container >  
        {highlightedUsp && highlightedUsp.length > 0 && (
  
          <UspPreviewGrid  nodes={highlightedUsp} />
        )} 
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{margin:'1rem'}}>
          <Button
            onClick={() => setShowContactModal(true)}
            buttonText='Book a demo'
          /></div>
        </div>
        {showContactModal && (
          <ContactModal onClose={() => setShowContactModal(false)} />
        )}
        {firstUsp && firstUsp.length > 0 && <UspPreviewGrid nodes={firstUsp} />}
      </Container>

     {/*  <Container wide={true} backgroundColor='#193033' textColor='white'>
        {pageNodes && (
          <PagePreviewGrid
            title='Learn more'
            itemColor='#FDF3F3'
            pages={pageNodes}
          />
        )}
      </Container> */}
      <Container>
        {secondUsps && secondUsps.length > 0 && (
          <UspPreviewGrid nodes={secondUsps} />
        )}
      </Container>

      <Container wide={true} backgroundColor='#0F5761' textColor='#faf0f0'>
        <h1 className={responsiveTitle2Centered}>
          {QuoteEng}
        </h1>
        <h3 style={{textAlign:'center'}}className={smallTitle}>Lars Dahle – Founder</h3>
      </Container>
      <Container wide={true} textColor='#1A1A1A' >
      <h2 className={styles.headline}>Independent Research</h2>

        <div style={{marginTop:'4rem'}}className={styles.grid3columns}>
          {bullets.map((b) => (
            <div className={styles.sellingPoints}>
              <div className={styles.sellingImage}>
                <img
                  src={imageUrlFor(buildImageObj(b.image))
                    .height(180)
                    .url()}
                  alt="hei"
                    />
              </div>
              <div className={styles.centered}>
                <BlockText blocks={b.bulletText} deckard={true} />
                </div>
            </div>
          ))}
        </div>
      </Container>
    
    {/*   <Container>
        {useCasesNodes && useCasesNodes.length > 0 && (
          <UseCasePreviewGrid
            title= 'Explore our solutions'
            nodes={useCasesNodes}
            browseMoreHref='/solution/'
          />
        )}

        {lastUsps && lastUsps.length > 0 && <UspPreviewGrid nodes={lastUsps} />}
      </Container> */}

 {/*      <Container wide={true} backgroundColor='#FFF9EE' textColor='#1A1A1A'>
        {featureNodes && featureNodes.length > 0 && (
          <FeaturePreviewGrid
            title={'More than a monitoring system – explore our features:'}
            browseMoreHref='/product'
            nodes={featureNodes}
          />
        )}
      </Container>
 */}
   {/*    {projectNodes && (
        <Container wide={true} backgroundColor='#fff' textColor='#1A1A1A'>
          <div style={{ maxWidth: 400, margin: 'auto' }}>
            <h4 className={responsiveTitle2SansCentered}>
              Learn more about our impact
            </h4>
          </div>
          <ProjectPreviewGrid
            title='Case Studies'
            nodes={projectNodes}
            browseMoreHref='/casestudies/'
          />
        </Container>
      )} */}

      <DemoPitch />

      <Container>
        {/*
        {postNodes && (
          <BlogPostPreviewGrid
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )}
        */}
      </Container>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
}

export default IndexPage
