import { Link } from 'gatsby'
import React from 'react'
import FeaturePreview from './feature-preview'
import * as styles from './feature-preview-grid.module.css'
import PropTypes from 'prop-types'

function FeaturePreviewGrid (props) {
  const { title, browseMoreHref, nodes} = props
  return (
    <div className={styles.root}>
      
      {title && (
        <h2 className={styles.headline}>
          {browseMoreHref ? (
            <Link to={browseMoreHref}>{title}</Link>
          ) : (
            title
          )}
        </h2>
      )}
      <ul className={styles.grid}>
        {nodes &&
          nodes.map(node => (
            <li key={node.id} >
              <FeaturePreview {...node} />
            </li>
          ))}
      </ul>
      {browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={browseMoreHref}>{'Browse more'}</Link>
        </div>
      )}
    </div>
  )
}

FeaturePreviewGrid.defaultProps = {
  title: 'Core Features',
  nodes: [],
  browseMoreHref: 'features'
}

FeaturePreviewGrid.propTypes = {
  title: PropTypes.string,
  nodes: PropTypes.array,
  browseMoreHref: PropTypes.string,
}

export default FeaturePreviewGrid
