import { Link } from 'gatsby'
import React from 'react'
import BlockText from '../block-content/block-text'
import * as styles from './feature-preview.module.css'
import { PropTypes } from 'prop-types'

function FeaturePreview (props) {
  const {slug, title, _rawExcerpt} = props

  if (slug && slug.current) {
    return (
      <Link className={styles.root} to={`/feature/${slug.current}`}>
        <div className={styles.item}>
          <h3 className={styles.title}>{title}</h3>

          {_rawExcerpt && (
            <div className={styles.excerpt}>
              <BlockText blocks={_rawExcerpt} />
            </div>
          )}
        </div>
      </Link> 
    )

  }
  else return (
    <div className={styles.root}>
      <div className={styles.item}>
        <h3 className={styles.title}>{title}</h3>

        {_rawExcerpt && (
          <div className={styles.fakeBox}>
            <div className={styles.excerpt}>
              <BlockText blocks={_rawExcerpt} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

FeaturePreview.propTypes = {
  title: PropTypes.string,
  _rawExcerpt: PropTypes.array,
  slug: PropTypes.object
}

export default FeaturePreview
